import axios from "axios";
import store from '../../../../store';
import {mappedAddresses} from "../../../utils";
import {find, map} from "lodash";
import {generateNewState, STATE_TRIGGERS} from "../states-and-triggers";
import {APPLICATIONS} from "../../../constants";


// COMPANY ADDRESSES //
export const handleFetchAddresses = async (payload, onSuccess, onError) => {
    const {companyId, page, itemsPerPage} = payload;
    const response = await axios.post(
        '/manager/expert3/xp3/company-addresses/' + companyId + '/',
        {
            page,
            itemsPerPage
        },
        {withCredentials: true}
    )

    if (response.data.result === 'Ok') {
        onSuccess({...response.data, addresses: mappedAddresses(response.data.addresses)})
    } else {
        onError('Loading company addresses failed. Please try again.')
    }
}

export const handleCompoundAddressChange = async (payload, onSuccess, onError) => {
    const {data, selected} = payload
    const response = await axios.post(
        '/manager/expert3/xp3/change-compound-address/',
        {data},
        {withCredentials: true}
    )

    if (response.data.result === 'Ok') {
        const updatedAddresses = map(store.getters['master/companyAddresses'], address => selected.includes(address.id) ? {...address, compound: !address.compound} : address)
        onSuccess({...response.data, addresses: updatedAddresses})
    } else {
        onError(response.data.message)
    }
}

export const handleDefaultAddressChange = async (payload, onSuccess, onError) => {
    const {companyId, addressId} = payload;
    const response = await axios.post(
        '/manager/expert3/xp3/change-default-address/' + companyId + '/' + addressId + '/',
        null,
        {withCredentials: true}
    )
    if (response.data.result === 'Ok') {
        const updatedAddresses = map(store.getters['master/companyAddresses'], address => (address.id === addressId ? {...address, default: true} : {...address, default: false}));
        onSuccess({...response.data, addresses: updatedAddresses})
    } else {
        onError(response.data.message)
    }
}

export const handleDeleteAddresses = async (payload, onSuccess, onError) => {
    const {page, companyId, recordsPerPage, data} = payload;
    const response = await axios.post(
        '/manager/expert3/xp3/delete-company-address/' + companyId + '/' + page + '/' + recordsPerPage + '/',
        {data},
        {withCredentials: true}
    )

    if (response.data.result === 'Ok') {
        // Add total number of addresses in vuex store
        store.commit('master/setTotalCompanyAddresses', response.data.totalCount);

        onSuccess({...response.data, addresses: mappedAddresses(response.data.addresses)})
    } else {
        onError(response.data.message)
    }
}

export const handleSaveAddress = async (payload, onSuccess, onError) => {
    const {addressId, companyId, page, itemsPerPage, address} = payload;
    const response = await axios.post(
        '/manager/expert3/xp3/edit-address/' + companyId + '/' + addressId + '/',
        {
            address,
            page,
            itemsPerPage
        },
        {withCredentials: true}
    )

    if (response.data.result === 'Ok') {
        if (!addressId) {
            // Add new total number of addresses in vuex store
            store.commit('master/setTotalCompanyAddresses', response.data.totalCount);
        }

        onSuccess({...response.data, addresses: mappedAddresses(response.data.addresses)})
    } else {
        onError(response.data.message)
    }
}


// COMPANY USERS //
export const handleFetchPaginatedUsers = async (payload, onSuccess, onError) => {
    const {companyId, page, itemsPerPage} = payload;
    const response = await axios.get(
        '/manager/expert3/xp3/company-users-paginated/' + companyId + '/' + page + '/' + itemsPerPage + '/',
        {withCredentials: true}
    )

    if (response.data.result === 'Ok') {
        // Add total number of addresses in vuex store
        store.commit('master/setTotalCompanyUsers', response.data.totalCount);

        onSuccess({...response.data, users: response.data.users})
    } else {
        onError('Loading company users failed. Please try again.')
    }
}

export const handleFetchUsers = async (payload, onSuccess, onError) => {
    const {companyId} = payload;
    const response = await axios.get(
        '/manager/expert3/xp3/company-users/' + companyId + '/',
        {withCredentials: true}
    )


    if (response.data.result === 'Ok') {
        onSuccess(response.data)
    } else {
        onError('Loading company users failed. Please try again.')
    }
}

export const handleFetchCompanyUser = async (payload, onSuccess, onError) => {
    const {companyId, userId} = payload;
    const response = await axios.get(
        '/manager/expert3/xp3/company-user/' + companyId + '/' + userId + '/',
        {withCredentials: true}
    )

    if (response.data.result === 'Ok') {
        onSuccess(response.data)
    } else {
        onError('Loading company user failed. Please try again.')
    }
}

export const handleDeleteUsers = async (payload, onSuccess, onError) => {
    const {page, recordsPerPage, users, companyId} = payload;
    const response = await axios.post(
        '/manager/expert3/xp3/delete-company-users/' + companyId + '/' + page + '/' + recordsPerPage + '/' + APPLICATIONS.MANAGER + '/',
        {users},
        {withCredentials: true}
    )

    if (response.data.result === 'Ok') {
        const updatedUsers = map(store.getters['master/companyUsers'], user => {
            const matched = find(users, u => u.userId === user.userId)
            return matched ? {...user, state: generateNewState(user.state, STATE_TRIGGERS.LOCK)} : {...user}
        })

        store.commit('master/setCompanyUsers', updatedUsers);

        onSuccess(response.data)
    } else {
        onError(response.data.message)
    }
}

export const handleChangeUsersStatus = async (payload, onSuccess, onError) => {
    const {companyId, users} = payload;
    const response = await axios.post(
        '/manager/expert3/xp3/change-company-users-state/' + companyId + '/' + APPLICATIONS.MANAGER + '/',
        {users},
        {withCredentials: true}
    )

    if (response.data.result === 'Ok') {
        const updatedUsers = map(store.getters['master/companyUsers'], user => {
            const matched = find(users, u => u.userId === user.userId)
            return matched ? {...user, state: generateNewState(user.state, matched.trigger)} : {...user}
        })

        store.commit('master/setCompanyUsers', updatedUsers);

        onSuccess(updatedUsers)
    } else {
        onError(response.data.message)
    }
}

export const handleUserInvitations = async (payload, onSuccess, onError) => {
    const {emails, companyId} = payload;

    const response = await axios.post(
        '/manager/expert3/xp3/send-invitations/' + companyId + '/' + 0 + '/',
        {emails},
        {withCredentials: true}
    )

    if (response.data.result === 'Ok') {
        onSuccess(response.data)
    } else {
        onError(response.data.message)
    }
}

export const handleResetUserPassword = async (payload, onSuccess, onError) => {
    const {userIds, companyId} = payload;

    const response = await axios.post(
        '/manager/expert3/xp3/reset-company-user-password/' + companyId + '/',
        {userIds},
        {withCredentials: true}
    )

    if (response.data.result === 'Ok') {
        onSuccess(response.data)
    } else {
        onError(response.data.message)
    }
}

export const handleSaveUser = async (payload, onSuccess, onError) => {
    const {userId, page, companyId, itemsPerPage, user} = payload;

    const response = await axios.post(
        '/manager/expert3/xp3/edit-user/' + APPLICATIONS.MANAGER + '/' + companyId + '/' + userId + '/',
        {
            user,
            page,
            itemsPerPage
        },
        {withCredentials: true}
    )

    if (response.data.result === 'Ok') {
        if (userId !== 0) {
            // Add new total number of addresses in vuex store
            store.commit('master/setTotalCompanyUsers', response.data.totalCount);
        }

        store.commit('master/setCompanyUsers', response.data.users)

        onSuccess({...response.data, users: response.data.users})
    } else {
        onError(response.data.message)
    }
}

export const handleFetchCompanyManagers = async (payload, onSuccess, onError) => {
    const {companyId} = payload;
    const response = await axios.get(
        '/manager/expert3/xp3/company-managers/' + companyId + '/',
        {withCredentials: true}
    )

    if (response.data.result === 'Ok') {
        onSuccess(response.data)
    } else {
        onError('Loading managers failed. Please try again.')
    }
}

export const handleFetchCompanyProfiles = async (onSuccess, onError) => {
    const response = await axios.get(
        '/manager/expert3/xp3/company-profiles/',
        {withCredentials: true}
    )

    if (response.data.result === 'Ok') {
        onSuccess(response.data)
    } else {
        onError('Loading profiles failed. Please try again.')
    }
}

/** COMPANY BUSINESS UNITS **/

export const handleFetchBusinessUnits = async (payload, onSuccess, onError) => {
    const {companyId, page, itemsPerPage} = payload;
    const response = await axios.post(
        '/manager/expert3/xp3/company-business-units/' + companyId + '/',
        {
            page,
            itemsPerPage
        },
        {withCredentials: true}
    )

    if (response.data.result === 'Ok') {
        onSuccess(response.data)
    } else {
        onError('Loading company business units failed. Please try again.')
    }
}

export const handleFetchBusinessUnit = async (payload, onSuccess, onError) => {
    const {businessUnitId, companyId} = payload;
    const response = await axios.get(
        '/manager/expert3/xp3/company-business-unit/' + businessUnitId + '/' + companyId + '/',
        {withCredentials: true}
    )

    if (response.data.result === 'Ok') {
        onSuccess(response.data)
    } else {
        onError('Loading business unit failed. Please try again.')
    }
}


export const handleSaveBusinessUnit = async (payload, onSuccess, onError) => {
    const {businessUnitId, page, companyId, itemsPerPage, businessUnit} = payload;
    const params = businessUnitId ? companyId + '/' + businessUnitId : companyId;
    const response = await axios.post(
        '/manager/expert3/xp3/edit-business-unit/' + params + '/',
        {
            businessUnit,
            page,
            itemsPerPage
        },
        {withCredentials: true}
    )

    if (response.data.result === 'Ok') {
        onSuccess(response.data)
    } else {
        onError(response.data.message)
    }
}

export const handleDeleteBusinessUnits = async (payload, onSuccess, onError) => {
    const {page, recordsPerPage, businessUnitIds} = payload;
    const response = await axios.post(
        '/manager/expert3/xp3/delete-business-units/',
        {
            businessUnitIds,
            page,
            recordsPerPage
        },
        {withCredentials: true}
    )

    if (response.data.result === 'Ok') {
        onSuccess(response.data)
    } else {
        onError(response.data.message)
    }
}

export const handleDeleteBusinessUnitGroups = async (payload, onSuccess, onError) => {
    const {page, recordsPerPage, groupIds} = payload;
    const response = await axios.post(
        '/manager/expert3/xp3/delete-groups/',
        {
            groupIds,
            page,
            recordsPerPage
        },
        {withCredentials: true}
    )

    if (response.data.result === 'Ok') {
        onSuccess(response.data)
    } else {
        onError(response.data.message)
    }
}

export const handleFetchBrands = async (payload, onSuccess, onError) => {
    const {companyId} = payload;
    const response = await axios.get(
        '/manager/expert3/xp3/company-brands/' + companyId + '/',
        {withCredentials: true}
    )

    if (response.data.result === 'Ok') {
        onSuccess(response.data)
    } else {
        onError('Loading brands failed. Please try again.')
    }
}

export const handleFetchGroups = async (payload, onSuccess, onError) => {
    const {companyId, page, itemsPerPage} = payload;
    const response = await axios.post(
        '/manager/expert3/xp3/company-business-unit-groups/' + companyId + '/',
        {
            page,
            itemsPerPage
        },
        {withCredentials: true}
    )

    if (response.data.result === 'Ok') {
        onSuccess(response.data)
    } else {
        onError('Loading groups failed. Please try again.')
    }
}


export const handleSaveGroup = async (payload, onSuccess, onError) => {
    const {groupId, page, itemsPerPage, group} = payload;
    const params = groupId ? groupId + '/' : '';
    const response = await axios.post(
        '/manager/expert3/xp3/edit-group/' + params + '/',
        {
            group,
            page,
            itemsPerPage
        },
        {withCredentials: true}
    )

    if (response.data.result === 'Ok') {
        onSuccess(response.data)
    } else {
        onError(response.data.message)
    }
}

export const handleFetchGroup = async (payload, onSuccess, onError) => {
    const {groupId} = payload;
    const response = await axios.get(
        '/manager/expert3/xp3/get-group/' + groupId + '/',
        {withCredentials: true}
    )

    if (response.data.result === 'Ok') {
        onSuccess(response.data)
    } else {
        onError('Loading group failed. Please try again.')
    }
}
